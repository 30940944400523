import PropTypes from 'prop-types';

import HelpersController from './../HelpersController';

let _this = {};

/**
 *
 */
class LayersCameras extends HelpersController {
    /**
     *
     * @param props
     * @param main
     */
    constructor(props, main) {
        super(props);

        this.props = props;
        this.main = main;

        _this = this;
    };

    /**
     *
     * @returns {*}
     */
    get = () => {
        const {current_name} = this.props;

        return this.main.getState(`_this_${current_name}`);
    };

    /**
     *
     * @returns {string}
     */
    occurrence = () => {
        const {currentFrame} = this.get();

        return this.padWithLeadingZeros(currentFrame, 2);
    };

    /**
     *
     * @param frame
     * @param visible
     */
    navigate = (frame, visible = false) => {
        const {occ_camera} = this.get();

        occ_camera.gotoAndStop(frame);
        visible && this.main.effectFadeIn(occ_camera, `occ_frame_${this.occurrence()}`);
    };

    /**
     *
     */
    close = () => {
        const {default_frame} = this.props;

        this.navigate(default_frame);
    };

    /**
     *
     * @param event
     */
    show = (event) => {
        let name = event.target.name;
        this.navigate(`frame${this.occurrence()}`, true);
    };

    /**
     *
     */
    create = () => {
        const
            {name} = this.props,
            {occ_camera, btn_cameras_show} = this.get();

        this.close();

        this.removeListener('click', `close-${name}`, this.close, () => {
            this.addListener(occ_camera.occ_content.btn_close, 'click', `close-${name}`, this.close);
        });

        this.removeListener('click', `show-${name}`, this.show, () => {
            this.addListener(btn_cameras_show, 'click', `show-${name}`, this.show);
        });
    };
}

/**
 *
 * @type {{default_frame: Requireable<string>, name: Requireable<string>, current_name: Requireable<string>}}
 */
const propTypes = {
    name: PropTypes.string,
    default_frame: PropTypes.string,
    current_name: PropTypes.string
};

/**
 *
 * @type {{default: {default_frame: string, name: string, current_name: null}}}
 */
const config = {
    default: {
        name: 'cameras',
        default_frame: 'frame00',
        current_name: null
    }
};

/**
 *
 * @param _this
 * @returns {function(*): LayersCameras}
 */
export default (_this) => (props) => {
    const defaultProps = {...config.default, ...props};
    PropTypes.checkPropTypes(propTypes, defaultProps, 'prop', 'LayersCameras');
    return (new LayersCameras(defaultProps, _this));
};
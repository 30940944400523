import PropTypes from 'prop-types';

import HelpersController from './../HelpersController';

let _this = {};

/**
 *
 */
class StagesPins extends HelpersController {
    /**
     *
     * @param props
     * @param main
     */
    constructor(props, main) {
        super(props);

        this.props = props;
        this.main = main;

        _this = this;
    };

    /**
     *
     * @returns {*}
     */
    get = () => {
        const {current_name} = this.props;

        return this.main.getState(`_this_${current_name}`);
    };

    /**
     *
     * @returns {string}
     */
    occurrence = () => {
        const {currentFrame} = this.get();

        return this.padWithLeadingZeros(currentFrame, 2);
    };

    /**
     *
     * @param visible
     */
    navigate = (visible = false) => {
        const
            _this_current = this.get(),
            {occ_icon_pins} = _this_current;

        occ_icon_pins.occ_more.visible = visible;
        occ_icon_pins.occ_less.visible = !visible;
        _this_current[`occ_frame_${this.occurrence()}`].occ_pins.visible = !visible;
    };

    /**
     *
     */
    close = () => {
        const {current_name, name} = this.props;

        this.navigate();

        this.main.createMarkers(current_name, `${name}-${this.occurrence()}`, true);
    };

    /**
     *
     */
    show = () => {
        const {occ_icon_pins} = this.get();

        this.navigate(!occ_icon_pins.occ_more.isVisible());
    };

    /**
     *
     */
    create = () => {
        const
            {name} = this.props,
            {btn_pins} = this.get();

        this.close();

        /**
         *
         *  Add event in button for hide and show pins.
         */
        this.removeListener('click', `show-${name}`, this.show, () => {
            this.addListener(btn_pins, 'click', `show-${name}`, this.show);
        });
    };
}

/**
 * 
 * @type {{name: Requireable<string>, current_name: Requireable<string>}}
 */
const propTypes = {
    name: PropTypes.string,
    current_name: PropTypes.string
};

/**
 * 
 * @type {{default: {name: string, current_name: null}}}
 */
const config = {
    default: {
        name: 'pins',
        current_name: null
    }
};

/**
 *
 * @param _this
 * @returns {function(*): StagesPins}
 */
export default (_this) => (props) => {
    const defaultProps = {...config.default, ...props};
    PropTypes.checkPropTypes(propTypes, defaultProps, 'prop', 'StagesPins');
    return (new StagesPins(defaultProps, _this));
};